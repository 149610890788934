<template>
    <div>
        <v-dialog v-if="dataSource" v-model="isVisible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-form ref="form" lazy-validation>
                                <template v-for="(item, itemKey) in dataSource">
                                    <v-row no-gutters v-if="item.group.label" :key=skey+itemKey+item.group.name>
                                        <v-col  cols="12" sm="12" md="12">
                                            <label class="f-label">{{$t(item.group.label)}}</label>
                                        </v-col>
                                    </v-row>
                                    <template v-for="(value, valueKey) in item.values">
                                        <component 
                                            :is="value.field.componentName" 
                                            :key=skey+itemKey+valueKey 
                                            :label="value.field.label" 
                                            :value="value.field.value" 
                                            @input="e => updateDataSource({ property: `[${itemKey}].values[${valueKey}].field.value`, value: e })" 
                                            :rules="requiredRule"
                                        />
                                    </template>
                                </template>
                            </v-form>
                        
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan"
                        text
                        depressed
                        @click="ok"
                        v-if="isValid"
                    >
                        {{ $t("Сформировать_и_отправить") }} 
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отменить")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "cscFormContract",
    data () {
        return {
            title: i18n.t("Сформировать_договор_и_отправить_заявителю"),
            contractDateMenu: false,
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    computed: {
        ...mapGetters('dialogs/cscContractInfo', ['isPending', 'isVisible', 'isValid', 'dataSource', 'skey']),
        ...mapGetters({ requiredRule: 'getRequiredRule' }),
    },    
    methods: {
        ...mapActions('dialogs/cscContractInfo', ['ok', 'cancel', 'updateDataSource']),        
        validateForm() {
            this.$refs?.form?.validate();    
        }
    },
    updated() {
        this.$nextTick(function () {
            if (this.isVisible)
                this.validateForm();
        });
    },
}
</script>